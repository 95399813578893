import React, { useContext, useEffect, useState } from "react";
import { FormNext } from "grommet-icons";
import { Modal, Button } from "react-bootstrap";
import { Container, Col, Row, Image } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import imageBackgroundTeam from "../../../components/images/imageBackgroundTeam.jpg";
import retur from "../../../styles/return.png";
import moment from "moment";
import SubNavBack from "./subNavBack";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectListStore from "./selectListStore";
import SelectListJobs from "./selectListJobs";
import SelectListDispo from "./selectListDispo";
import SelectListSports from "./selectListSports";
import es from "date-fns/locale/es";
import "moment/locale/es";
import SelectListCalendar from "./selectListCalendar";
import axios from "axios";
import { Loader } from "../../Loader";
import { Redirect } from "react-router-dom";
import { AuthContext } from "../../../auth/AuthContext";
import { types } from "../../../types/types";
import { BACKEND } from "../../backend";

registerLocale("es", es);
setDefaultLocale("es");
const CrearVacante = () => {
  let history = useHistory();
  const [calendario, setCalendario] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [showTitle, setShowTitle] = useState("");
  const [showTitleField, setShowTitleField] = useState(false);
  const [job, setJob] = useState("");
  const [sportName, setSportName] = useState("");
  const [showSportName, setShowSportName] = useState(false);
  const [store, setStore] = useState("");
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(true);

  const [errors, setErrors] = useState({
    name: "",
    description_project: "",
    description_responsibilities: "",
    description_who_you_are: "",
    store_id: "",
    charge_id: "",
    schedule_id: "",
    duration_period: "",
    sport_id: "",
  });
  // Estados  de la respuesta
  const [res, setRes] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [fichaOficio, setFichaOficio] = useState(null);

  const [form, setForm] = useState({
    name: "",
    description_project: "",
    description_responsibilities: "",
    description_who_you_are: "",
    store_id: "",
    charge_id: "",
    schedule_id: "",
    duration_period: "",
    sport_id: "",
    // image: ""
  });
  const baseUrl = BACKEND + "api/cms/jobs";
  const token = localStorage.getItem("token");

  // NOMBRE DEL USUARIO CREADOR DE VACANTE, SOLO SE MUESTRA EN VISTA PREVIA...
  const { dispatch } = useContext(AuthContext);

  // const [saludo, setSaludo] = useState("");

  const [respProfile, setRespProfile] = useState(null);

  const baseUrlSubNav = BACKEND + "api/common/profile";

  const fetchSaludo = async () => {
    try {
      const response = await axios({
        url: baseUrlSubNav,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      });
      setRespProfile(response);

      setError(null);
    } catch (error) {
      dispatch({
        type: types.adminlogout,
      });
      setError(error);
      localStorage.clear();
      history.replace("/");
      window.location.reload();
    } finally {
    }
  };

  const fetchFO = async (id) => {
    console.log("en fetch FOsss: " + id);
    await axios({
        url: `${BACKEND}api/cms/fo-list/` + id,
        method: "GET",
        headers: { Authorization: "Bearer " + token },
    })
    .then((response) => {
        setFichaOficio(response);

        console.log("response ne 54" + response.toString());
    })
    .catch((error) => {
        console.log(error);
    });
  };

  const handleShow = (vac) => {
    setShow(true);
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setForm({ ...form, duration_period: start + ", " + end });
  };
  useEffect(() => {
    let sportTitle = sportName && showSportName ? ` ${sportName} en ` : ' ';
    if(store != "Servicios"){
      setShowTitle(job + sportTitle +  store);
      setShowTitleField(false);
    }

    if(store == "Servicios"){
      setShowTitleField(true);
      
    }

    setForm({ ...form, name: showTitle});

    fetchSaludo();

}, [job, store, showTitle, sportName, showSportName]);
  const handleChange = event => {
    setShowTitle(event.target.value);
  };
  const onChangeStore = (value) => {
    setForm({ ...form, store_id: value.value });
    setStore(value.label);
    setErrors({ ...errors, store_id: false });
  };
  const onChangeJob = (value) => {
    // setForm({ ...form, charge_id: value.value });
    // setJob(value.label + " en ");
    if(Number(value.value) === 1 || Number(value.value) === 2){
        setShowSportName(true);
        setJob(value.label);
    }else{

        setShowSportName(false);
        setJob(value.label + " en ");
    }
    console.log("antes de fetchFO" )
    //fetchFO(value.value);
    console.log("despues de fetchFO new" )

    let desc_vacante_text;              
    let description_project_text;
    let description_responsibilities_text;
    let description_who_you_are_text;
          
    //console.log("response FO:" + fichaOficio );
    //console.log("response proye FO:" + JSON.stringify(fichaOficio));
    //console.log("response FO 123:" + JSON.stringify(fichaOficio.data.success));

    if(value.value == 1){
      // Vendedor
      desc_vacante_text                 = "En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos. \n Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito:  'Move people through the wonders of sports'. \n Nos interesa tu perfil deportivo y comercial. \n Si te apasionan el deporte, el servicio al cliente, las ventas y ser parte de un DreamTeam… \n Convierte tu pasión por el deporte en una aventura profesional.";
      description_project_text          = "-Servicio al cliente: dar ganas de hacer volver a cada cliente gracias a un servicio extraordinario.\n-Asesor a cliente.\n-Garantizar la imagen y el orden de mi área de trabajo. \n-Contribuir a la seguridad de mis clientes, compañeros y establecimiento.";
      description_responsibilities_text = "-Eres practicante de alguna actividad deportiva.\n-Proactiv@ y propositiv@.\n-Tienes facilidad en el uso de herramientas digitales.\n-Tienes gusto por el servicio al cliente. \n-Destacas por tu trabajo en equipo y tu comunicación asertiva.\n-Por ser un sector retail, se requiere disponibilidad 100% en fines de semana.\n-No se requiere experiencia previa.";
      description_who_you_are_text      = "-Base Salarial mensual de $8,791 a $12,876. \n  -Horarios flexibles. \n -Primas. \n -30% de descuento sobre nuestros productos. \n -Vales de despensa. \n -Capacitaciones y plan de carrera. \n -Aguinaldo de 30 días.\n -Prima Vacacional del 50%. \n  -Prestaciones de ley. \n -2 días de descanso por semana. \n -Seguro de gastos medicos mayores (desde el primer día). \n -18 días de vacaciones desde día uno. \n  -Convenios y descuentos sobre lugares y actividades deportivas.";
    }else if(value.value == 2){
      // Responsable
      desc_vacante_text                 = "En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos. \n Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito:  'Move people through the wonders of sports'. \n Nos interesa tu perfil deportivo y comercial. \n Si te apasionan el deporte, el servicio al cliente, las ventas y ser parte de un DreamTeam… \n Convierte tu pasión por el deporte en una aventura profesional.";
      description_project_text          = "Comercial:\n-Garantizar la experiencia del cliente a través de soluciones físicas o digitales que satisfagan sus necesidades.\n-Asegurar la rentabilidad de tu departamento y aplicar los planes de acción necesarios para llegar a los objetivos establecidos. \n-Garantizar que tu departamento esté brindando el mejor servicio al cliente (Best in class) en el piso de ventas, en los probadores y en las cajas manteniendo los estándares de Decathlon.\n-Asegurar que toda la mercancía se reciba y se muestre correctamente de acuerdo con nuestras pautas y estrategias comerciales.\n-Impulsar la consecución de los objetivos de venta de la tienda, cumpliendo al mismo tiempo los objetivos de venta departamentales, liderando al equipo de tu departamento para superar las expectativas del cliente. \n Humano:\n-Identificar las mejores fuentes de atracción de talentos.\nReclutar los mejores talentos para el proyecto que lideras.\nDesarrollar talentos, al tiempo que se evalúa continuamente en conjunto con el Gerente de tienda el rendimiento de cada colaborador y se proporciona retroalimentación constante para impulsar los resultados y alcanzar los objetivos comerciales y humanos de la tienda.\nAsegurar la gestión administrativa del equipo del cual eres responsable de acuerdo a las políticas establecidas por Decathlon.\n Operación:\nDesempeñar eficazmente las funciones operativas: apertura y cierre de la tienda, cajas y procedimientos de gestión administrativa.\nGarantiza la seguridad y la prevención de pérdidas en la tienda para que el inventario y el flujo de mercancías sean precisos y se minimice la pérdida desconocida en la empresa.\nContribuir a la seguridad de mí mismo, compañeros y establecimiento.";
      description_responsibilities_text = "-Has concluido tus estudios superiores.\n-Eres practicante de alguna actividad deportiva.\n-Proactiv@ y propositiv@.\n-Manejo de sistemas de ventas e inventarios\n-Tienes habilidades de liderazgo.\n-Tienes gusto por el servicio al cliente.\n-Eres un ejemplo para tu equipo y actúas como un modelo a seguir.\n-Por ser un sector retail, se requiere disponibilidad 100% en fines de semana.";
      description_who_you_are_text      = "-Base Salarial mensual $18,000. \n-Horario flexible.\n-Primas.\n-30% de descuento sobre nuestros productos.\n-Vales de despensa.\n-Capacitaciones y plan de carrera.\n -Aguinaldo de 30 días.\n -Prima Vacacional del 50%.\n-Prestaciones de ley.\n-2 días de descanso por semana.\n-Seguro de gastos medicos mayores (desde el primer día).\n-18 días de vacaciones desde día uno.\n-Convenios y descuentos lugares actividades deportivas."
   }else if(value.value == 16){
      desc_vacante_text                 = "En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos. \n Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito:  'Move people through the wonders of sports'. \n Nos interesa tu perfil deportivo y comercial. \n Si te apasionan el deporte, el servicio al cliente, las ventas y ser parte de un DreamTeam… \n Convierte tu pasión por el deporte en una aventura profesional.";
      description_project_text          = "-Servicio al cliente: dar ganas de hacer volver a cada cliente gracias a un servicio extraordinario.\n-Garantizar el surtido de pedidos en tiempo y forma.\n-Garantizar el orden y limpieza de mi área de trabajo. \n-Contribuir a la seguridad mí mismo, compañeros y establecimiento.\n-Servicio al cliente.\n-Contribuir a la seguridad de mis clientes, compañeros y establecimiento.";
      description_responsibilities_text = "-Eres practicante de alguna actividad deportiva.\n-Tienes facilidad y gusto en el uso de herramientas digitales.\n-Proactiv@ y propositiv@.\n-Tienes gusto por el servicio al cliente.\n-Destacas por tu trabajo en equipo y tu comunicación asertiva.\n-Por ser un sector retail, se requiere disponibilidad 100% en fines de semana.\n-No se requiere experiencia previa.";
      description_who_you_are_text      = "-Base Salarial mensual de $7,800 a $11,500. \n  -Horarios flexibles. \n -Primas. \n -30% de descuento sobre nuestros productos. \n -Vales de despensa. \n -Capacitaciones y plan de carrera. \n -Aguinaldo de 30 días.\n -Prima Vacacional del 50%. \n  -Prestaciones de ley. \n -2 días de descanso por semana. \n -Seguro de gastos medicos mayores (desde el primer día). \n -18 días de vacaciones desde día uno. \n  -Convenios y descuentos sobre lugares y actividades deportivas.";
    }else {
      desc_vacante_text                 = "En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos. \n Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito:  'Move people through the wonders of sports'. \n Nos interesa tu perfil deportivo y comercial. \n Si te apasionan el deporte, el servicio al cliente, las ventas y ser parte de un DreamTeam… \n Convierte tu pasión por el deporte en una aventura profesional.";
      description_project_text          = "-Servicio al cliente: dar ganas de hacer volver a cada cliente gracias a un servicio extraordinario.\n-Garantizar el orden y limpieza de mi área de trabajo. \n-Contribuir a la seguridadde mí mismo, compañeros y establecimiento.";
      description_responsibilities_text = "-Eres practicante de alguna actividad deportiva.\n-Tienes facilidad y gusto en el uso de herramientas digitales.\n-Proactiv@ y propositiv@.\n-Destacas por tu trabajo en equipo y tu comunicación asertiva.";
      description_who_you_are_text      = "-Base Salarial mensual $18,000. \n-Horario flexible.\n-Primas.\n-30% de descuento sobre nuestros productos.\n-Vales de despensa.\n-Capacitaciones y plan de carrera.\n -Aguinaldo de 30 días.\n -Prima Vacacional del 50%.\n-Prestaciones de ley.\n-2 días de descanso por semana.\n-Seguro de gastos medicos mayores (desde el primer día).\n-18 días de vacaciones desde día uno.\n-Convenios y descuentos lugares actividades deportivas."
    }
    

    setForm({ ...form, desc_vacante: desc_vacante_text , description_project: description_project_text, description_who_you_are: description_who_you_are_text, description_responsibilities: description_responsibilities_text, charge_id: value.value  });
    // setForm({ ...form, description_responsibilities: "Description de las responsabilidades" });
    // setForm({ ...form, description_who_you_are: "Description de quien eres"});
    //setForm({ ...form, description_project: "Description del proyecto", description_who_you_are: "Description de quien eres", description_responsibilities: "Description de las responsabilidades", charge_id: value.value  });
    // setErrors({ ...errors, description_project: false });
    // setErrors({ ...errors, description_responsibilities: false });
    // setErrors({ ...errors, description_who_you_are: false });
    setErrors({ ...errors, charge_id: false , description_project: false, description_who_you_are: false, description_responsibilities: false });


    // console.log("tras fetch:" + fichaOficio);
  };
  const onChangeSchedule = (value) => {
    setForm({ ...form, schedule_id: value.value });
    setErrors({ ...errors, schedule_id: false });
  };
  const onChangeSport = (id) => {
    console.log("En onChangeSport" + id);
    setForm({ ...form, sport_id: id });
    setErrors({ ...errors, sport_id: false });

  };







  // setForm({ ...form, description_project: "Description proyect" });
  // setErrors({ ...errors, description_project: false });







  
  const onChangeProj = (e) => {
    setForm({ ...form, description_project: e.target.value });
    setErrors({ ...errors, description_project: false });
  };
  const onChangeRespo = (e) => {
    setForm({ ...form, description_responsibilities: e.target.value });
    setErrors({ ...errors, description_responsibilities: false });
  };
  const onChangeWho = (e) => {
    setForm({ ...form, description_who_you_are: e.target.value });
    setErrors({ ...errors, description_who_you_are: false });
  };

  const onChangeCalendario = (value) => {
    if (value.value === true) {
      setCalendario(value.value);
      setErrors({ ...errors, duration_period: false });
    } else if (value.value === false) {
      setCalendario(value.value);
      setForm({ ...form, duration_period: "Indeterminado" });
      setErrors({ ...errors, duration_period: false });
    }
  };

  const fetchData = async () => {
    try {
      const resp = await axios({
        url: baseUrl,
        method: "POST",
        headers: { Authorization: "Bearer " + token },
        data: form,
      });
      setTimeout(() => {
        setRes(resp);
        setLoading(false);
      }, 2500);
      setError(null);
    } catch (error) {
      setError(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2500);
    }
  };
  const Validacion = () => {
    setLoading(true);
    if (
      (errors.store_id,
      errors.charge_id,
      errors.schedule_id,
      errors.sport_id,
      errors.duration_period,
      errors.description_project,
      errors.description_responsibilities,
      errors.description_who_you_are === false)
    ) {
    fetchData();
    } else if (form.store_id === 0 || form.store_id === "") {
      setLoading(false);
      setErrors({ ...errors, store_id: true });
    } else if (form.charge_id === 0 || form.charge_id === "") {
      setLoading(false);
      setErrors({ ...errors, charge_id: true });
    } else if (form.schedule_id === 0 || form.schedule_id === "") {
      setLoading(false);
      setErrors({ ...errors, schedule_id: true });
    } else if (form.sport_id === 0 || form.sport_id === "") {
      setLoading(false);
      setErrors({ ...errors, sport_id: true });
    } else if (form.duration_period === 0 || form.duration_period === "") {
      setLoading(false);
      setErrors({ ...errors, duration_period: true });
    } else if (
      form.description_project === 0 ||
      form.description_project === ""
    ) {
      setLoading(false);
      setErrors({ ...errors, description_project: true });
    } else if (
      form.description_responsibilities === 0 ||
      form.description_responsibilities === ""
    ) {
      setLoading(false);
      setErrors({ ...errors, description_responsibilities: true });
    } else if (
      form.description_who_you_are === 0 ||
      form.description_who_you_are === ""
    ) {
      setLoading(false);
      setErrors({ ...errors, description_who_you_are: true });
    }
  };

  const exp = {
    textArea: /[A-Za-z0-9_\-\u4e00-\u9fa5]+/,
  };

  // BLUR PARA LOS TEXT AREA

  const handleBlur = (e) => {
    switch (e.target.name) {
      case "description_project":
        // name
        if (exp.textArea.test(form.description_project.trim())) {
          setErrors({ ...errors, description_project: false });
        }
        if (!form.description_project.trim()) {
          setErrors({ ...errors, description_project: true });
        } else if (!exp.textArea.test(form.description_project.trim())) {
          setErrors({ ...errors, description_project: true });
        }
        break;
      case "description_responsibilities":
        // name
        if (exp.textArea.test(form.description_responsibilities.trim())) {
          setErrors({ ...errors, description_responsibilities: false });
        }
        if (!form.description_responsibilities.trim()) {
          setErrors({ ...errors, description_responsibilities: true });
        } else if (
          !exp.textArea.test(form.description_responsibilities.trim())
        ) {
          setErrors({ ...errors, description_responsibilities: true });
        }
        break;
      case "description_who_you_are":
        // name
        if (exp.textArea.test(form.description_who_you_are.trim())) {
          setErrors({ ...errors, description_who_you_are: false });
        }
        if (!form.description_who_you_are.trim()) {
          setErrors({ ...errors, description_who_you_are: true });
        } else if (!exp.textArea.test(form.description_who_you_are.trim())) {
          setErrors({ ...errors, description_who_you_are: true });
        }
        break;

      default:
        break;
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleClose2 = () => {
    setShow2(false);
  };

  return (
    <>
      <SubNavBack />
      {res && res.data.success === 1 && (
        <Redirect to="/cms/auth/admin-vacantes" />
      )}
      <div className="container-fluid  bg-back">
        <div className="row justify-content-center">
          <div className="col-10  bg-white bgShadow mb-3 animate__animated animate__fadeIn animate__slow">
            {!loading ? (
              <>
                <div className="row  justify-content-center">
                  <div className="titBack text-center col-12 my-md-5 pt-md-5">
                    CREAR UNA VACANTE
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-10">
                    <form>
                      {showTitleField &&
                        <div className="form-row" >
                        <div className="form-group col-md-12">
                          <label htmlFor="inputTitle" className="titInputBack">
                            TÍTULO
                          </label>
                          <input
                            type="text"
                            id="inputTitle"
                            className="form-control"
                            onChange={handleChange}
                            value={showTitle}
                          />
                        </div>
                      </div>
                      }

                      <div className="form-row pt-md-3">
                        <div className="form-group col-md-4">
                          <label className="titInputBack" htmlFor="inputState">
                            TIENDA/CORPORATIVO
                          </label>
                          <div
                            style={{
                              border:
                                errors.store_id == true &&
                                "1.1px solid #ff0000",
                              borderRadius: errors.store_id == true && "5px",
                            }}
                          >
                            <SelectListStore
                              title="store"
                              List="store-list"
                              onChange={onChangeStore}
                            />
                          </div>
                          {errors.store_id == true && (
                            <p className="text-danger text-errors">
                              {" "}
                              El campo Tienda es requerido, seleccione una
                              opción.
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label className="titInputBack" htmlFor="inputState">
                            OFICIO
                          </label>
                          <div
                            style={{
                              border:
                                errors.charge_id == true &&
                                "1.1px solid #ff0000",
                              borderRadius: errors.charge_id == true && "5px",
                            }}
                          >
                            <SelectListJobs
                              title="jobs"
                              List="jobs-list"
                              onChange={onChangeJob}
                            />
                          </div>
                          {errors.charge_id == true && (
                            <p className="text-danger text-errors">
                              {" "}
                              El campo Oficio es requerido, seleccione una
                              opción.
                            </p>
                          )}
                        </div>
                        <div className="form-group col-md-4">
                          <label className="titInputBack" htmlFor="inputState">
                            DISPONIBILIDAD
                          </label>
                          <div
                            style={{
                              border:
                                errors.schedule_id == true &&
                                "1.1px solid #ff0000",
                              borderRadius: errors.schedule_id == true && "5px",
                            }}
                          >
                            <SelectListDispo
                              title="Schedule"
                              List="schedule-list"
                              onChange={onChangeSchedule}
                            />
                          </div>
                          {errors.schedule_id == true && (
                            <p className="text-danger text-errors">
                              {" "}
                              El campo Disponibilidad es requerido, seleccione
                              una opción.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="form-row pt-md-3 justify-content-between">
                        <div className="form-group col-md-6 pr-4">
                          <label className="titInputBack" htmlFor="inputState">
                            DEPORTE PRACTICADO
                          </label>
                          <div
                            style={{
                              border:
                                errors.sport_id == true &&
                                "1.1px solid #ff0000",
                              borderRadius: errors.sport_id == true && "5px",
                            }}
                          >
                            <SelectListSports
                              title="Deporte Practicado"
                              url={BACKEND + "api/data-register"}
                              cat="cat_sports_new"
                              onChange={onChangeSport}
                              setSportName={setSportName}
                            />
                          </div>
                          {errors.sport_id == true && (
                            <p className="text-danger text-errors">
                              {" "}
                              El campo Deporte Practicado es requerido,
                              seleccione una opción.
                            </p>
                          )}
                        </div>

                        <div className="form-group  col-md-6 pl-4">
                          <label className="titInputBack">
                            TIPO DE CONTRATO
                          </label>
                          <div
                            style={{
                              border:
                                errors.duration_period == true &&
                                "1.1px solid #ff0000",
                              borderRadius:
                                errors.duration_period == true && "5px",
                            }}
                          >
                            <SelectListCalendar onChange={onChangeCalendario} />
                            {calendario && (
                              <>
                                <br />
                                <DatePicker
                                  className="p-2 inputCalendar pt-2"
                                  selected={startDate}
                                  onChange={onChange}
                                  startDate={startDate}
                                  endDate={endDate}
                                  selectsRange
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  locale="es"
                                  dateFormat="dd/MM/yyyy"
                                  minDate={new Date()}
                                  showWeekNumbers
                                />{" "}
                                {startDate && endDate && (
                                  <span className="pl-4">
                                    Desde:{" "}
                                    <span className="  text-primary ">
                                      {" "}
                                      {moment(startDate).format("LL")}
                                    </span>{" "}
                                    hasta:
                                    <span className="text-primary">
                                      {" "}
                                      {moment(endDate).format("LL")}{" "}
                                    </span>{" "}
                                    .
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                          {errors.duration_period == true && (
                            <p className="text-danger text-errors">
                              {" "}
                              El campo Duración es requerido, seleccione una
                              opción.
                            </p>
                          )}
                        </div>
                      </div>
                      <div className=" col-12   align-items-center text-center my-5">
                        <span
                          className="btn-verGris  cursor-pointer"
                          onClick={() => {
                            handleShow();
                          }}
                        >
                          VISTA PREELIMINAR <FormNext color="white" />
                        </span>
                      </div>
                      <div className=" col-12   align-items-center text-center my-5">


                        <span
                          className="btn-ver cursor-pointer "
                          onClick={() => Validacion()}
                        >
                          PUBLICAR <FormNext color="white" />
                        </span>

                      </div>
                    </form>
                  </div>
                </div>
              </>
            ) : (
              <Loader s="Vacante creada correctamente" />
            )}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modalW"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton className="bgBlue">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              VISTA PREVIA
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-10">
              <Container fluid style={{ backgroundColor: "#D9DDE1" }}>


                <div onClick={handleClose} className="d-flex flex-row-reverse">
                  <button className="btn-regresar-admin ">
                    <Image src={retur} /> Volver
                  </button>
                </div>

                <Container style={{ backgroundColor: "#fff" }}>
                  <Row>
                    <Col style={{ padding: "0" }}>
                      <Image src={imageBackgroundTeam} style={{ width: "100%" }} />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-desc ">
                      <h3>{form.name !== "" && form.name}</h3>
                      <p className="spacePre text-justify">
                        {form.desc_vacante !== "" &&
                          form.desc_vacante}
                      </p>
                      <h4>ACTIVIDADES</h4>
                      <p className="spacePre text-justify">
                        {form.description_project !== "" &&
                          form.description_project}
                      </p>
                      <h4>REQUISITOS</h4>
                      <p className="spacePre text-justify">
                        {form.description_responsibilities !== "" &&
                          form.description_responsibilities}
                      </p>
                      <h4>PRESTACIONES</h4>
                      {/* <b>**Tu perfil</b> */}
                      <p className="spacePre text-justify">
                        {form.description_who_you_are !== "" &&
                          form.description_who_you_are}
                      </p>
                    </Col>
                    <Col className="resp-sel">
                      <div>
                        <p>RESPONSABLE DE SELECCIÓN</p>
                        <b style={{ color: "#000", fontSize: "24px" }}>
                          {JSON.parse(localStorage.getItem("user")) &&
                            JSON.parse(localStorage.getItem("user")).isAdmin ===
                              true && (
                              <>
                                {!error &&
                                  respProfile !== null &&
                                  respProfile.data.name}
                              </>
                            )}
                        </b>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <button className="btn-aplicar">
                        {" "}
                        APLICAR AHORA &gt;
                      </button>
                    </div>
                  </Row>
                </Container>
              </Container>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton className="bgBlue" id="cv">
          <div className=" container">
            <div className="titBack text-center col-12 my-1 text-white ">
              AVISO IMPORTANTE !!
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-10">
              <Container fluid style={{ backgroundColor: "#D9DDE1" }}>
                Antes de crear una vacante, te invitamos a revisar la Base de Datos en busca de algún perfil acorde a las necesidades de tu proyecto: 
                <a href="https://trabajoen.decathlon.com.mx/#/cms/auth/base-datos-cv"> Consultar Base de Datos</a>
              </Container>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose2}>
            Continuar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CrearVacante;

import React from "react";
import { Container, Col, Row, Image } from "react-bootstrap";
import imageBackgroundTeam from "../components/images/imageBackgroundTeam.jpg";
import imageBackgroundTeamRunning from "../components/images/runing.png";

import { useParams, Link } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { BACKEND } from "../components/backend";
const Formulario = () => {
  const { job_id } = useParams();
};
function DescOfert(idx) {
  const jobId = idx.match.params.job_id;
  const url = BACKEND+"api/jobs-list/all";

  const { data } = useFetch(url);
  if (!data) return null;
  const datosRes = data.data;

  const result = datosRes.find(({ job_id }) => job_id === parseInt(jobId));


  let render, render2, renderTop, nombre, desc_vacante_text;

  let imgBack =  imageBackgroundTeam;
  
  if(result.schedule_id = 13){
    imgBack = imageBackgroundTeamRunning;
    desc_vacante_text = 'Buscamos personas como tú: deportistas apasionad@s por el running, que conocen la importancia de un buen calzado, un plan de entrenamiento o el ambiente de una carrera. Si sientes esa adrenalina antes de cruzar la meta... este equipo es para ti.'
  } else {
    desc_vacante_text = "En Decathlon, clientes y colaboradores tenemos una pasión en común: el deporte y su accesibilidad a todos. \n Somos un equipo de deportistas dinámicos y entusiastas, que comparten un mismo propósito:  'Move people through the wonders of sports'. \n Nos interesa tu perfil deportivo y comercial. \n Si te apasionan el deporte, el servicio al cliente, las ventas y ser parte de un DreamTeam… \n Convierte tu pasión por el deporte en una aventura profesional.";
  }
  

  if (result !== null) {
    renderTop = (
      <>
        <h3>{result.job_name}</h3>
        <br />
        {result.job_duration_date_1 === ""  ? "" : <h5>Contrato temporal: De {result.job_duration_date_1} a {result.job_duration_date_2}</h5> }
      </>
    );
    render = (
      <>
        <h4>EL PROYECTO</h4>
        <p className="spacePre text-justify">
        {desc_vacante_text}
        </p><br />
        <br />
        <h4>ACTIVIDADES</h4>
        <p className="spacePre text-justify">
          {  result.job_description_project  }
        </p><br />
      </>
    );
    render2 = (
      <>
        <h4>REQUISITOS</h4> 
        <p className="spacePre text-justify">
          {result.job_description_responsibilities}
        </p><br />
        <h4>PRESTACIONES</h4> 
        <p className="spacePre text-justify">
          {result.job_description_who_you_are}<br />
        </p>
      </>
    );
    nombre = (
      <>
        <b style={{ color: "#000", fontSize: "24px" }}>{result.publisher}</b>
      </>
    );
  }

  return (
    <>
      <Container fluid className=" bg-back">
        {/* <Link to="/team">
                    <button className="btn-regresar"> <Image src={retur} /> Volver a la lista de ofertas</button>
                </Link> */}
        <Row>
          <Col style={{ padding: "0" }}>
            <Image src={imgBack}  className="descImage"/>
          </Col>
        </Row>
        <Container className="bg-white bgShadow2 mb-3 animate__animated animate__fadeIn">
          <Row>
            <Col className="col-desc">{renderTop}</Col>
            <Col className="resp-sel">
              <div >
                <p>RESPONSABLE DE LA VACANTE</p>
                {nombre}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-desc">{render}</Col>
            <Col className="col-desc">
              <div>
                {render2}
              </div>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/perfil/${jobId}?`}>
              <button className="btn-aplicar"> APLICAR AHORA &gt;</button>
            </Link>
            <br />
            <br />
            &nbsp;
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default DescOfert;